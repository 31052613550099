import React, { useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { useStaticQuery, graphql } from 'gatsby'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const IconList = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    // ScrollTrigger to animate icon list
    const iconListTl = gsap.timeline({
      scrollTrigger: {
        trigger: '.icon-list',
        start: 'top 60%',
      },
    })
    iconListTl
      .from('.icon-list li', {
        duration: 1,
        opacity: 0,
        y: -50,
        stagger: 0.5,
      })

    return () => {
      // We kill all scroll triggers so the next pages can recreate them.
      const triggers = ScrollTrigger.getAll()
      if (triggers) {
        triggers.forEach((trigger) => {
          trigger.kill()
        })
      }
    }
  }, [])

  const features = useStaticQuery(graphql`
    query {
      icons: allFeatureBlocksYaml {
        nodes {
          block {
            title
            description
            icon {
              publicURL
              relativePath
            }
          }
        }
      }
    }
  `)

  const featureBlocks = features.icons.nodes.map((node, index) => (
    <li key={`icon-list-${index}`} className="d-flex align-items-center">
      <div className="icon-list-text">
        <h3 className="fw-bold text-uppercase mb-0 fs-5">{node.block.title}</h3>
        <p className="mb-0 fs-5">{node.block.description}</p>
      </div>
    </li>
  ))

  return (

    <section className="py-3 bg-light feature-blocks">
      <Container fluid="md">
        <Row>
          <Col lg="7" className="d-flex align-items-center">
            <h2 className="mb-2 mb-md-3 mb-lg-0 d-flex flex-wrap fw-bolder align-items-end lh-1 text-info good-energy-thats-it">
              <em className="font-swish order-0">Good</em>
              <em className="w-100 ps-3 ps-md-4 font-swish order-2">
                Energy
                <sup>™</sup>
              </em>
              <span className="bg-info fw-bold py-14 px-12 px-md-1 py-xl-12 rounded-6 order-1 ms-1 mb-14 text-light">That's It.</span>
            </h2>
          </Col>
          <Col lg="5">
            <ul className="icon-list list-unstyled mb-0 p-2 border-info border border-2 rounded-6 text-info">
              {featureBlocks}
            </ul>
          </Col>
        </Row>
      </Container>
    </section>

  )
}

export default IconList
