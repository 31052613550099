import React, { useRef } from 'react'
import ReactPlayer from 'react-player/lazy'

const VideoPlayerBasic = (props) => {
  const player = useRef(null)
  const {
    ratio, containerClass, url, title,
  } = props

  return (

    <div className={`video-container ratio ratio-${ratio} rounded-4 overflow-hidden position-relative ${containerClass}`}>
      <ReactPlayer
        width="100%"
        height="100%"
        url={url}
        ref={player}
        playing
        loop
        muted
        volume={0}
        playsinline
        config={{
          vimeo: {
            title,
          },
        }}
      />
    </div>

  )
}

export default VideoPlayerBasic
