import React, { useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { useStaticQuery, graphql } from 'gatsby'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import VideoPlayerBasic from '../common/video-player-basic'

const MyceliumScroller = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)
    gsap.to(window, { duration: 0.35, scrollTo: 0 })

    const videos = gsap.utils.toArray('.video-container--mycelium-scroller')
    const textblocks = gsap.utils.toArray('.scroller-list li')
    const totalTextblocks = textblocks.length

    const mm = gsap.matchMedia()
    mm.add('(min-width: 768px)', () => {
      const pinTl = gsap.timeline({
        scrollTrigger: {
          pin: '#myceliumScrollerContainer',
          start: 'center center+=60',
          endTrigger: '.scroller-list li:last-child',
          end: 'center center',
          scrub: 1,
          pinSpacing: false,
        },
      })
    })

    // ScrollTrigger to animate each key point
    textblocks.forEach((block, i) => {
      // Make sure all but first image are transparent
      if (i !== 0) {
        gsap.set(videos[i], { autoAlpha: 0 })
      }
      // Define timeline
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: block,
          start: 'top center+=200',
          end: 'bottom center',
          scrub: true,
        },
      })
      // Fade text and videos in/out
      tl
        .to(block, { autoAlpha: 1, duration: 0.5 })
        .to(block, { duration: 2 })
        .to(videos[i], { autoAlpha: 1 }, '<')

      if (i + 1 !== totalTextblocks) {
        tl.to(block, { autoAlpha: 0, duration: 0.5 })
      }
    })

    return () => {
      // We kill all scroll triggers so the next pages can recreate them.
      const triggers = ScrollTrigger.getAll()
      if (triggers) {
        triggers.forEach((trigger) => {
          trigger.kill()
        })
      }
    }
  }, [])

  const data = useStaticQuery(graphql`
    query {
      icons: allHowItsMadeYaml {
        nodes {
          block {
            description
            title
            video
          }
        }
      }
    }
  `)
  return (
    <section className="pt-3 pb-2" id="myceliumScroller">
      <div id="myceliumScrollerPin">
        <Container fluid className="d-flex justify-content-center">
          <h2 className="text-center mb-2 mb-md-3 fw-bold">Growing Meati from the Ground Up</h2>
        </Container>
        <Container id="myceliumScrollerContainer" fluid="xxl" className="pb-md-4">
          <Row>
            <Col md="6" className="scroller-image-column">
              <div className="scroller-image-wrapper">
                {
                  data.icons.nodes.map((node, index) => (
                    <div className="scroller-block" key={`how-block-${index}`}>

                      <VideoPlayerBasic
                        ratio="1x1"
                        title="my great title"
                        url={node.block.video}
                        containerClass="video-container--mycelium-scroller rounded-5 overflow-hidden"
                      />
                      <div className="d-md-none px-1 px-sm-2 py-2 py-sm-3">
                        <h3 className="fs-1">{node.block.title}</h3>
                        <p className="mb-0">{node.block.description}</p>
                      </div>
                    </div>
                  ))
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div id="myceliumScrollerText" className="d-none d-md-block mb-md-5">
        <Container fluid="xxl">
          <Row>
            <Col sm={{ span: 6, offset: 6 }}>
              <ul className="list-unstyled scroller-list px-1 px-xl-3 mb-0">
                {
                  data.icons.nodes.map((node, index) => (
                    <li key={`how-text-${index}`}>
                      <div>
                        <h3 className="mb-1 pb-1">{node.block.title}</h3>
                        <p className="mb-0">{node.block.description}</p>
                      </div>
                    </li>
                  ))
                }
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  )
}

export default MyceliumScroller
